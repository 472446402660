export const UF_OPTIONS = [
  {
    value: "PR",
    label: "PR",
  },
];

export const handleEsconderDadosVeiculos = (
  esconder: boolean,
  dadoVeiculo: string
) => {
  if (esconder && dadoVeiculo.length > 2) {
    const primeiroCaractere = dadoVeiculo.charAt(0);
    const ultimoCaractere = dadoVeiculo.charAt(dadoVeiculo.length - 1);
    const asteriscos = "*".repeat(dadoVeiculo.length - 2);
    return primeiroCaractere + asteriscos + ultimoCaractere;
  }
  return dadoVeiculo;
};

export const handleEsconderPlaca = (esconder: boolean, dadoVeiculo: string) => {
  if (esconder && dadoVeiculo.length > 2) {
    const ultimosTresCaracteres = dadoVeiculo.slice(-3);
    const parteComAsteriscos = "*".repeat(dadoVeiculo.length - 3);
    return parteComAsteriscos + ultimosTresCaracteres;
  }
  return dadoVeiculo;
};
