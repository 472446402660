import { Stronger } from "components/Stronger";
import { Section } from "../Section";
import { FaDownload, FaSpinner } from "react-icons/fa";
import { ButtonContainer, RotacionarIcone } from "./style";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfExtratoVeicular } from "../PdfExtratoVeicular";
import { IDetalhesExtratoVeicular } from "../..";
import temaPadrao from "_config/temas/estilo/base";
import { useMask } from "hooks/useMask";

export interface IDadosProprietario {
  nomeProprietario: string;
  cpfCnpjProprietario: string;
}

interface Props {
  detalhesExtratoVeicular: IDetalhesExtratoVeicular;
  mostrarLogo?: boolean;
  mostrarDadosProprietario?: boolean;
  esconderDadosVeiculo?: boolean;
}

export function DadosProprietario({
  detalhesExtratoVeicular,
  mostrarLogo = true,
  mostrarDadosProprietario = true,
  esconderDadosVeiculo = false,
}: Readonly<Props>) {
  const { mCPFCNPJ } = useMask();

  return (
    <Section.Root>
      <Section.Header>
        <Section.Titulo
          titulo={mostrarDadosProprietario ? "Dados do proprietário" : ""}
        />
        <ButtonContainer>
          <PDFDownloadLink
            style={{ color: temaPadrao.colors.primary.main }}
            fileName={`Extrato Veicular - ${
              detalhesExtratoVeicular.dadosVeiculo[0].placa ?? "-"
            }`}
            document={
              <PdfExtratoVeicular
                dadosPDF={detalhesExtratoVeicular}
                mostrarLogo={mostrarLogo}
                mostrarDadosProprietario={mostrarDadosProprietario}
                esconderDadosVeiculo={esconderDadosVeiculo}
              />
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <RotacionarIcone>
                  <FaSpinner />
                </RotacionarIcone>
              ) : (
                <>
                  <FaDownload /> Exportar
                </>
              )
            }
          </PDFDownloadLink>
        </ButtonContainer>
      </Section.Header>
      {mostrarDadosProprietario && (
        <Section.Body>
          <Section.Row>
            <Section.Col>
              <span>CPF/CNPJ</span>
              <br />
              <Stronger>
                {detalhesExtratoVeicular.dadosVeiculo[0].cpfCnpjProprietario
                  ? mCPFCNPJ(
                      detalhesExtratoVeicular.dadosVeiculo[0]
                        .cpfCnpjProprietario
                    )
                  : "-"}
              </Stronger>
            </Section.Col>
            <Section.Col>
              <span>Nome</span>
              <br />
              <Stronger>
                {detalhesExtratoVeicular.dadosVeiculo[0].nomeProprietario
                  ? detalhesExtratoVeicular.dadosVeiculo[0].nomeProprietario.toLocaleUpperCase()
                  : "-"}
              </Stronger>
            </Section.Col>
          </Section.Row>
        </Section.Body>
      )}
    </Section.Root>
  );
}
