import { ContextPermissao } from "hooks/ContextPermissao";
import { ReactNode, useContext } from "react";

interface Props {
  children: ReactNode;
}

export function InternoLw({ children }: Props) {
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);

  if (verificarUsuarioAwLw()) {
    return <>{children}</>;
  }

  return null;
}
