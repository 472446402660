import { ModalUpload } from "components/ModalUpload";
import { UploadContextProvider } from "contexts/UploadContext";
import { ArquivosRoutes } from "features/arquivos/router";
import { CadastroUsuarioRoutes } from "features/cadastro/usuarios/router";
import Page401 from "features/core/pages/401";
import Page404 from "features/core/pages/404";
import { CoreRoutes } from "features/core/routes";
import { ConsultaRoutes } from "features/debito/consulta/router";
import { PagamentoRoutes } from "features/debito/pagamento/router";
import { ResumoExtrato } from "features/extrato-veicular/consultar-veiculos/pages/ResumoExtrato";
import { ExtratoVeicularRoutes } from "features/extrato-veicular/routes";
import { LicenciamentoRoutes } from "features/licenciamento/router";
import { Login } from "features/Login/pages/Login";
import { RelatorioConsultaRoutes } from "features/relatorios/consultas/router";
import { RelatorioExtratoVeicularRoutes } from "features/relatorios/extratoVeicular/routers";
import { RelatorioPagamentoRoutes } from "features/relatorios/pagamento/router";
import { RelatorioPagamentosRecusadosRoutes } from "features/relatorios/recusados/router";
import { UsuarioRoutes } from "features/usuario/router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRouter";
import { ResumoExtratoDownload } from "features/extrato-veicular/consultar-veiculos/pages/ResumoExtrato/Download";

export function AppRouters() {
  return (
    <BrowserRouter>
      <UploadContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/debito/consulta/*"
            element={<PrivateRoute component={ConsultaRoutes} />}
          />
          <Route
            path="/debito/pagamento/*"
            element={<PrivateRoute component={PagamentoRoutes} />}
          />
          <Route
            path="/core/*"
            element={<PrivateRoute component={CoreRoutes} />}
          />
          <Route path="/401" element={<Page401 />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/*" element={<Page404 />} />
          <Route
            path="/relatorios/pagamento/*"
            element={<PrivateRoute component={RelatorioPagamentoRoutes} />}
          />
          <Route
            path="/relatorios/consulta/*"
            element={<PrivateRoute component={RelatorioConsultaRoutes} />}
          />
          <Route
            path="/relatorios/recusados/*"
            element={
              <PrivateRoute component={RelatorioPagamentosRecusadosRoutes} />
            }
          />
          <Route
            path="/relatorios/extrato/veicular/*"
            element={
              <PrivateRoute component={RelatorioExtratoVeicularRoutes} />
            }
          />
          <Route
            path="/cadastro/usuarios/*"
            element={<PrivateRoute component={CadastroUsuarioRoutes} />}
          />
          <Route
            path="/usuario/*"
            element={<PrivateRoute component={UsuarioRoutes} />}
          />
          <Route
            path="/licenciamento/*"
            element={<PrivateRoute component={LicenciamentoRoutes} />}
          />
          <Route
            path="/arquivos/*"
            element={<PrivateRoute component={ArquivosRoutes} />}
          />
          <Route
            path="/veiculos/extrato/resumo/:idLoteExtratoVeicular/:idExtratoVeicular/"
            element={<ResumoExtrato />}
          />
          <Route
            path="/veiculos/extrato/resumo/download/:idLoteExtratoVeicular/:idExtratoVeicular/"
            element={<ResumoExtratoDownload />}
          />
          <Route
            path="/veiculos/extrato/*"
            element={<PrivateRoute component={ExtratoVeicularRoutes} />}
          />
        </Routes>
        <ModalUpload />
      </UploadContextProvider>
    </BrowserRouter>
  );
}
