import { pdf } from "@react-pdf/renderer";
import { setTokenIntegracao } from "_services/auth";
import { Loading } from "components/Loading";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PdfExtratoVeicular } from "../../ExtratoVeicularDetalhado/containers/PdfExtratoVeicular";
import { useExtratoVeicularDetalhado } from "../../ExtratoVeicularDetalhado/hooks/useExtratoVeicularDetalhado";
import "../styles.css";

function ResumoExtratoDownload() {
  const [searchParams] = useSearchParams();
  const { idExtratoVeicular, idLoteExtratoVeicular } = useParams();

  const { detalhesExtratoVeicular, loading, error } =
    useExtratoVeicularDetalhado(
      idExtratoVeicular,
      idLoteExtratoVeicular,
      false,
      "consultaIntegracao"
    );

  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      setTokenIntegracao(token);
    }
  }, [token]);

  useEffect(() => {
    const downloadPDF = async () => {
      if (detalhesExtratoVeicular) {
        const documentoPdf = (
          <PdfExtratoVeicular
            dadosPDF={detalhesExtratoVeicular}
            mostrarLogo={false}
            mostrarDadosProprietario={false}
            esconderDadosVeiculo={true}
          />
        );

        const blob = await pdf(documentoPdf).toBlob();
        const url = URL.createObjectURL(blob);

        const a = document?.createElement("a");

        a.href = url;
        a.download = `Extrato Veicular - ${
          detalhesExtratoVeicular.dadosVeiculo[0].placa ?? "-"
        }.pdf`;
        a.click();

        URL.revokeObjectURL(url);

        setTimeout(() => {
          window.close();
        }, 2000);
      }
    };

    downloadPDF();
  }, [detalhesExtratoVeicular]);

  if (error) {
    return (
      <div className="contanier-error">
        <h1>Oops! Algo deu errado.</h1>
        <p>Tente novamente mais tarde.</p>

        <button className="button" onClick={() => window.location.reload()}>
          Recarregar Página
        </button>
      </div>
    );
  }

  if (loading || !detalhesExtratoVeicular) {
    return (
      <div className="contanier-error">
        <Loading show={loading} />
      </div>
    );
  }

  return null;
}

export { ResumoExtratoDownload };
