import { Stronger } from "components/Stronger";
import { Section } from "../Section";
import { handleEsconderDadosVeiculos, handleEsconderPlaca } from "features/extrato-veicular/constants";

interface IDadosVeiculo {
  placa: string;
  renavam: string;
  chassi: string;
  situacao: string;
  municipio: string;
  marcaModelo: string;
  anoFabricacao: string;
  anoModelo: string;
  cor: string;
  combustivel: string;
  especie: string;
  categoria: string;
  codigoMotor: string;
  situacaoChassi: string;
}

interface Props {
  dadosVeiculo: IDadosVeiculo;
  esconderDadosVeiculo?: boolean;
}

export function DadosVeiculo({
  dadosVeiculo,
  esconderDadosVeiculo = false,
}: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Titulo titulo="Dados do veículo" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col>
            <span>Placa</span>
            <br />
            <Stronger>
              {dadosVeiculo.placa
                ? handleEsconderPlaca(
                    esconderDadosVeiculo,
                    dadosVeiculo.placa
                  )
                : "-"}
            </Stronger>
          </Section.Col>
          <Section.Col>
            <span>Renavam</span>
            <br />
            <Stronger>
              {dadosVeiculo.renavam
                ? handleEsconderDadosVeiculos(
                    esconderDadosVeiculo,
                    dadosVeiculo.renavam
                  )
                : "-"}
            </Stronger>
          </Section.Col>
          <Section.Col>
            <span>Chassi</span>
            <br />
            <Stronger>
              {dadosVeiculo.chassi
                ? handleEsconderDadosVeiculos(
                    esconderDadosVeiculo,
                    dadosVeiculo.chassi
                  )
                : "-"}
            </Stronger>
          </Section.Col>
          <Section.Col>
            <span>Situação</span>
            <br />
            <Stronger>{dadosVeiculo.situacao ?? "-"}</Stronger>
          </Section.Col>
        </Section.Row>
        <Section.Row>
          <Section.Col>
            <span>Município</span>
            <br />
            <Stronger>{dadosVeiculo.municipio ?? "-"}</Stronger>
          </Section.Col>
          <Section.Col>
            <span>Marca / Modelo</span>
            <br />
            <Stronger>{dadosVeiculo.marcaModelo ?? "-"}</Stronger>
          </Section.Col>
          <Section.Col>
            <span>Ano de fabricação / modelo</span>
            <br />
            <Stronger>
              {dadosVeiculo.anoFabricacao && dadosVeiculo.anoModelo
                ? `${dadosVeiculo.anoFabricacao}/${dadosVeiculo.anoModelo}`
                : "-"}
            </Stronger>
          </Section.Col>
          <Section.Col>
            <span>Cor</span>
            <br />
            <Stronger>{dadosVeiculo.cor ?? "-"}</Stronger>
          </Section.Col>
        </Section.Row>
        <Section.Row>
          <Section.Col lg="3" md="3" sm="2">
            <span>Combustível</span>
            <br />
            <Stronger>{dadosVeiculo.combustivel ?? "-"}</Stronger>
          </Section.Col>
          <Section.Col lg="3" md="3" sm="2">
            <span>Espécie</span>
            <br />
            <Stronger>{dadosVeiculo.especie ?? "-"}</Stronger>
          </Section.Col>
          <Section.Col lg="3" md="3" sm="2">
            <span>Categoria</span>
            <br />
            <Stronger>{dadosVeiculo.categoria ?? "-"}</Stronger>
          </Section.Col>
          <Section.Col lg="3" md="3" sm="2">
            <span>Número motor</span>
            <br />
            <Stronger>{dadosVeiculo.codigoMotor ?? "-"}</Stronger>
          </Section.Col>
        </Section.Row>
        <Section.Row>
          <Section.Col lg="3" md="3" sm="2">
            <span>Situação chassi</span>
            <br />
            <Stronger>{dadosVeiculo.situacaoChassi ?? "-"}</Stronger>
          </Section.Col>
        </Section.Row>
      </Section.Body>
    </Section.Root>
  );
}
